.wrapper{
    display: inline-block;
    position:relative;
  }

  .img_0_right{
    position:absolute;
    right: 80%;
    bottom: 0%;
    width: 5vh;
    max-width: 7vh;
  }

  .img_1_right{
    width: 14vh;
    max-width: 14vh;
  }

  .img_2_right{
    position:absolute;
    left: 70%;
    bottom: 50%;
    width: 6vh;
    max-width: 6vh;
  }

  .img_3_right{
    position:absolute;
    left: 50%;
    top: 60%;
    width: 5vh;
    max-width: 5vh;
  }


  .img_0_left{
    position:absolute;
    right: 70%;
    top: 10%;
    width: 7vh;
    max-width: 7vh;
  }

  .img_1_left{
    width: 12vh;
    max-width: 12vh;
  }

  .img_2_left{
    position:absolute;
    left: 70%;
    bottom: 50%;
    width: 6vh;
    max-width: 6vh;
  }

  .img_3_left{
    position:absolute;
    left: 50%;
    top: 70%;
    width: 6vh;
    max-width: 6vh;
  }