@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);

/* Font Smoothing */
body,
h1,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: $font-weight-normal;
}

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.0em;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
  text-transform: uppercase;
}

h3 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.2em;
  font-weight: 200;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
}

h4 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.1em;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
  text-shadow:
        0.02em 0 black;
  text-transform: uppercase;
}

h5 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 0.8em;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
}
p {
margin:2px 0px;
font-size:1em;
}
b {
  font-weight: 550;
}


.btn-secondary a {
color:#ffffff;
text-decoration: none;
}

.btn-outline-secondary {
  color: #225cb6;
  border-color: #225cb6;
}
.serach-tab {
  border-color:#ffffff;
  box-shadow: 0px 2px 2px lightgray;
}
.btn-tag {
  background:#225cb6;
  color: #225cb6;
  border-color:#225cb6;
  padding:6px;
  border-radius: 5px;
  text-transform: uppercase;
  margin: 2px;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
}
.btn-tag a {
	color:#f5f6f8;
	padding: 5px;

}
.btn-tag a:hover {
	color:#f8f9fa;
}
.btn-tag2 {
  background:#ffffff;
  color: #f5f6f8;
  border-color:#225cb6;
  padding:6px;
  border-radius: 5px;
  text-transform: uppercase;
  margin: 2px;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
}
.btn-tag2 a {
	color:#225cb6;
	padding: 5px;
}
.btn-tag2 h4 {
	color:#225cb6;
}

.btn-tag2 a:hover {
	color:#f8f9fa;
}
.btn-tag2 h4:hover {
	color:#f8f9fa;
}

.col-md-4 {
float:left;}

.social {
float:left;}

.card-header {
font-weight: 500;
text-align:center;
radius:none;
}

.svg-inline--fa {
	color:#3183e6;
}
.ProjectCard-body {
text-align:center;
padding: 0;
}
