.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


.td h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.0em;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
  text-transform: uppercase;
}

.td h4 {
  color: rgb(71, 71, 71);
  font-weight: 850;
  font-size: 1.3em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
}
.td {
    height:60px!important;
    text-decoration:none;
}

.MuiTableRow-root:hover {
    background-color: #f8f9fa;
    border-bottom:1px solid #f8f9fa;
    text-decoration:none;
}
.MuiTableRow-root {
    border-bottom:1px solid #f8f9fa;
    text-underline:none;
    height:50px!important;
}

.card-img-left {
  border-radius: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card-img-right {
  border-radius: 0;
  border-top-right-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}



.card {
  background: #ffffff;
  border-radius: 0.5em;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
  margin-bottom:20px;
  opacity: 1.0;
}

.card-header {
  background: #fefefe;
  border-radius: 0.5em!important;
  border-bottom: 0px;
  box-shadow: 0 8px 10px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.1), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.01);
  transition: all ease 200ms;
  
}

.inner {
  overflow: hidden;
  transition: max-height 0.2s ease;
}

strong {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 0.8em;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
}


.card h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.0em;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
  text-transform: uppercase;
}

.card h3 {
  color: rgb(87, 85, 85);
  font-weight: 850;
  font-size: 0.9em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
}


.card h4 {
  color: rgb(87, 85, 85);
  font-weight: 300;
  font-size: 0.9em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;

}

.card h5 {
  color: rgb(114, 112, 112);
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
}

.cardCentered {
  background: rgb(248, 248, 248);
  border-radius: 0.6em;
  margin: 1em;
  // overflow: hidden;
  // cursor: pointer;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
  align-items: center;
}


.ProjectCards {
  // display: flex;
  // align-items: flex-start;
    justify-content: center;
    padding: 0.2em;

}

.ProjectCard {
  background: #f9f9fa;
  border-radius: 2.6em;
  margin: 0.0em;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 13px 27px -5px rgba(26, 26, 27, 0.25), 0 8px 16px -8px hsla(251, 96%, 49%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
  padding: 0;
  opacity: 1.0;
  border: 1px solid #225cb6;
}

.ProjectCard:hover {
  transform: scale(1.03);
  box-shadow: 0 53px 40px -5px #225cb6, 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.ProjectCard img {
  width: 100%;
  object-fit: scale-down;
}

.ProjectCard a:hover {
  text-decoration:none;
}

.ProjectCard h2 {
  color: #222;
  line-height: 1.4;
  font-size: 1.0em;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
  transition: all ease-in 100ms;
  text-transform: uppercase;
}

.ProjectCard p {
  color: #777;
}

.ProjectCard h4 {
  color: rgb(71, 71, 71);
  font-weight: 800;
  font-size: 0.9em;
  letter-spacing: 0.04em;
}

.ProjectCard h5 {
  color: rgb(126, 125, 125);
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.ProjectCard-body {
  padding: 0.2em;
}

#name::placeholder {
  color: #225cb6;
  opacity: 1;
}
#name::defaultValue {
  color: #225cb6;
  opacity: 1;
}

.table .thead-light th {
  background-color: #f6f9fc;
  color: #6a7c91;
align-items: flex-start; }


.carousel.carousel-slider {
  margin: 0px auto;
  overflow: visible;
}

.carousel .slide {
  color: white;
  background: #225cb6;
  opacity:0.80;
}

.carousel .slide-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.carousel .slide img {
  margin: 30px;
  width: 35%;
  height: 200px;
  object-fit: contain;
}
.carousel .slide iframe {
  margin: 30px;
  width: 35%;
}
.carousel .text-container {
  padding: 30px;
  text-align: left;
}

.carousel .text-container h2 {
  margin-top: 0px;
}

.carousel .text-container p:last-child {
  margin-bottom: 0px;
}

.carousel .control-dots {
  bottom: -40px;
}

.carousel .thumbs-wrapper {
  margin: 30px;
}

    